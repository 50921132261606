


import React from 'react'
import './experience-mobile.css';
import './experience-tablet.css';
import './experience.css';

import { Container, Row, Col } from "reactstrap";

const developmentExperienceData = [

  
  {
    year: 'Association Passerelle 2022',
    title: 'Stage en tant que Développeur Web et Web mobile',
    desc: `Lors de ma formation chez Webforce 3, j'ai eu l'opportunité faire un stage qui m'a permis de créer un site web pour l'association.`,
    skill1 : 'Autonomie',
    skill2 : 'Conseil client'

  },

  {
    year: 'Webforce3 2022',
    title: 'Formation de Développeur Web et Web mobile ',
    desc: `Cela m'a permis d'acquérir de les expériences et des connaissances pour exercer ce métier.`,
    skill1 : 'Bases du Front-end',
    skill2 : `Bases du Back-end`
  },


  {
    year: 'Inco Academy 2021',
    title: 'Les métiers du numérique',
    desc: `Découvertes des métiers numériques (Développeur Web, WebDesigneur, SEO ... )`,
    skill1 : 'WordPress',
    skill2 : 'HTML et CSS'
  },



]


const developmentExperienceData2 = [

  {
    year: 'Greta Auxerre 2020',
    title: 'DAQ 2.0',
    desc: 'Consolider son projet professionnel et apprendre à se découvrir',
    skill1 : 'Confiance en soi',
    skill2 : `CLEA`
  },


  {
    year: 'Géant Casino 2019',
    title: 'Employé de commerce polyvalent',
    desc: 'Mise en rayon de différents produits/Conseils clients',
    skill1 : 'Polyvalence',
    skill2 : 'Marketing'

  },

  {
    year: 'Boulangerie Rameau 2017',
    title: 'Apprenti Boulanger',
    desc: 'Confection des différents produits',
    skill1 : 'Endurance',
    skill2 : 'Rigueur'

  },

  
]


export const Experience = () => {
  return (
    <Container className='mt-5 reveal' id="experience">
      <Row>
        <Col lg="12" className="mt-5">
          <h2 className='section_title mt-5'>Expériences</h2>
        </Col>

        <Col lg="6" md='6' className=''>
          <div className="single_experience-container">

            {developmentExperienceData.map((item, index) => (
              <div className="single_experience" key={index}>
                <span className="experience_icon"><i class="ri-briefcase-line"></i></span>
                <h6>{item.year}</h6>
                <h5>{item.title}</h5>
                <p>{item.desc}</p>
                <ul className='mb-4 ul_exp'>
                  <li>{item.skill1}</li>
                  <li>{item.skill2}</li>
                </ul>
              </div>
            ))}
          </div>
        </Col>

        <Col lg="6" md='6'>
          <div className="single_experience-container exp-t2">
            {developmentExperienceData2.map((item, index) => (
              <div className="single_experience" key={index}>
                <span className="experience_icon"><i className="ri-briefcase-line"></i></span>
                <h6>{item.year}</h6>
                <h5>{item.title}</h5>
                <p>{item.desc}</p>
                <ul className='ul_exp'>
                  <li>{item.skill1}</li>
                  <li>{item.skill2}</li>
                </ul>
              </div>
            ))}
          </div>
        </Col>

      </Row>
    </Container>
  )
}
