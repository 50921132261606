
import React, { useRef } from 'react'
import './contact-mobile.css'
import './contact-tablet.css'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { ImWhatsapp } from 'react-icons/im'

import ReCAPTCHA from "react-google-recaptcha";
import emailjs from '@emailjs/browser';



function onChange(value) {
    console.log("Captcha value:", value);
}

const Contact = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zhqe80w', 'template_adjiork', e.target, 'c3yoEyQUeyio0iYQZ')
            .then(({ status }) => {
                console.log("EMAILJS SENT", status.code);
            }, (err) => {
                console.log("EMAILJS ERROR", err);
            });

        e.target.reset();
    };

    return (
        <section className="reveal" id='contact'>
            <h2 className='section_title mb-5'>Contact</h2>

            <div className="contaier contact_container mx-auto">
                <div className="contact_options">
                    <article className="contact_option">
                        <MdOutlineEmail className='contact_option-icon' />
                        <h4>Email</h4>
                        <h5 className='mail-h5'>lucasbesnard89@gmail.com</h5>
                        <a href="mailto:lucasbesnard89@gmail.com" target="_blank" className='btn btn-contact mt-3'>Me Contacter</a>
                    </article>

                    <article className="contact_option">
                        <ImWhatsapp className='contact_option-icon' />
                        <h4>Téléphone</h4>
                        <h5><a href="tel:+33666663217" target="_blank" >06 66 66 32 17</a></h5>
                    </article>

                    <article className="contact_option">
                        <HiOutlineLocationMarker className='contact_option-icon' />
                        <h4>Location</h4>
                        <h5>France, Auxerre</h5>
                    </article>
                </div>
                {/* ENF OF CONACT OPTION  */}
                <form ref={form} onSubmit={sendEmail}>
                    <input type="text" name='name' placeholder='Votre Nom' required />
                    <input type="email" name='email' placeholder='Votre Email' required />
                    <textarea name="message" rows="7" placeholder='Votre Messaage' required></textarea>
                    <ReCAPTCHA
                        sitekey="6Lc76HogAAAAAE0SUNG6nGxMErRh31khaAdKhumw"
                        onChange={onChange}
                    />
                    <button type='submit' className="btn btn-send">Envoyer le message</button>

                  
                </form>
            </div>
        </section>
    )
}

export default Contact