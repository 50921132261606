import React from 'react'

import './education.css'



const Education = () => {
  return <table className="table">
        <thead>
            <tr>
                <th>Diplôme</th>
                <th>Départements</th>
                <th>Entreprises</th>
                <th>Année</th>
            </tr>
        </thead>

        <tbody>
            <tr>
                <td>Bac +2</td>
                <td>Dijon</td>
                <td>Webforce 3</td>
                <td>2022</td>
            </tr>

            <tr>
                <td>Bac +2</td>
                <td>Dijon</td>
                <td>Webforce 3</td>
                <td>2022</td>
            </tr>
            
            <tr>
                <td>Bac +2</td>
                <td>Dijon</td>
                <td>Webforce 3</td>
                <td>2022</td>
            </tr>
        </tbody>

  </table>

}

export default Education