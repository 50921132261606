

import React from 'react'
import './portfolio-mobile.css'
import './portfolio-tablet.css'
import './portfolio.css'


import thumb1 from'../../assets/images/portfolio/association.jpg';
import thumb2 from '../../assets/images/portfolio/portfolio.jpg';
import thumb3 from '../../assets/images/portfolio/blog.jpg';
import thumb4 from'../../assets/images/portfolio/quizz.jpg';
import thumb5 from '../../assets/images/portfolio/responsive.jpg';
import thumb6 from '../../assets/images/portfolio/jovinienne.jpg';

const Portfolio = () => {
    return (
        <section className='projects section reveal mt-5' id="portfolio">
            <h2 className="section_title h2-port">Portfolio</h2>


            <div className="wrap">
                <div className="tile">

                    <img className='w-100' src={thumb1} alt="image-portfolio" />

                    <div className="text anim1">
                        <h1 className="animate-text">Association Passerelle</h1>
                        <h2 className="animate-text">Site pro</h2>
                        <p className="animate-text"> Création d'un site internet avec Symfony</p>
                    </div>
                </div>


                <div className="tile">
                <img className='w-100' src={thumb2} alt="image-portfolio" />
                    <div className="text port-anim">
                        <h1 className="animate-text">Portfolio</h1>
                        <h2 className="animate-text">Site perso</h2>
                        <p className="animate-text">React Js</p>
                    </div>
                </div>

                <div className="tile">
                <img className='w-100' src={thumb3} alt="image-portfolio" />
                    <div className="text anim3">
                        <h1 className="animate-text">Blog</h1>
                        <h2 className="animate-text">Projet personnel</h2>
                        <p className="animate-text">Petit blog d'entrainement avec Symfony 6</p>
                    </div>
                </div>
            </div>

            <div className="wrap mt-1">
                <div className="tile">

                    <img className='w-100' src={thumb4} alt="image-portfolio" />

                    <div className="text anim4">
                        <h1 className="animate-text">Quizz Game</h1>
                        <h2 className="animate-text">Soutenance</h2>
                        <p className="animate-text">Projet fait en groupe avec Symfony</p>
                    </div>
                </div>


                <div className="tile">
                <img className='w-100' src={thumb5} alt="image-portfolio" />
                    <div className="text anim5">
                        <h1 className="animate-text">Intégration CSS</h1>
                        <h2 className="animate-text">Projet personnel</h2>
                        <p className="animate-text">Création de plusieurs intégrations pour m'entrainer</p>
                    </div>
                </div>

                <div className="tile">
                <img className='w-100' src={thumb6} alt="image-portfolio" />
                    <div className="text anim6">
                        <h1 className="animate-text">La Jovinienne</h1>
                        <h2 className="animate-text">Hackathon (Site pro)</h2>
                        <p className="animate-text">Réalisation d'un site internet pour une entreprise bio avec Wordpress (en équipe)</p>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default Portfolio