import React, { useState, useRef, useEffect } from 'react'


const CountUp = ({ start = 0,end }) => {
    const [state, setstate] = useState(null)
    const ref = useRef(start)
  
    const accumulator = end / 200;
  
    const updateCounterState = () => {
      if (ref.current < end) {
        const result =Math.ceil(ref.current + accumulator)
        if (result > end) return setstate(end)
        setstate(result);
        ref.current = result;
      }
      setTimeout(updateCounterState, 1000) 
    };
    
    useEffect(() => {
        let isMounted = true;
        if(isMounted) {updateCounterState();
        }

      return () => (isMounted = false)
    }, [end, start]);
    
  
  return <div>{state}</div>
};

export default CountUp