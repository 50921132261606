import React from 'react'
import './skills.css'



// const frontendSkills = [

//     {
//         title: 'HTML/CSS',
//         percentage: '60%'
//     },

//     {
//         title: 'JavaScript',
//         percentage: '40%'
//     },


//     {
//         title: 'WordPress',
//         percentage: '65%'
//     },

//     {
//         title: 'Bootstrap',
//         percentage: '35%'
//     },

// ]


// const backendSkills = [

//     {
//         title: 'PHP',
//         percentage: '35%'
//     },

//     {
//         title: 'MySQL',
//         percentage: '20%'
//     },

//     {
//         title: 'Symfony',
//         percentage: '55%'
//     },

//     {
//         title: 'Github',
//         percentage: '55%'
//     },

// ]




const Skills = () => {
    return (
        <div className="container">
            <div id="left">
                <ul className="skills-bar-container">
                    <label>HTML / CSS</label>
                    <span className="percent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-htmlcss"></span>
                    </li>

                    <label>JavaScript / jQuery</label>
                    <span className="percent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-jsjq"></span>
                    </li>

                    <label>Wordpress</label>
                    <span className="percent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-wordpress"></span>
                    </li>

                    <label>Bootstrap</label>
                    <span className="percent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-jsjq"></span>
                    </li>
                </ul>
            </div>
            <div id="right">
                <ul className="skills-bar-container">
                    <label>PHP</label>
                    <span className="percent" id="angular-pourcent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-php"></span>
                    </li>

                    <label>SQL / MySQL</label>
                    <span className="percent" id="angular-pourcent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-sql"></span>
                    </li>

                    <label>Symfony</label>
                    <span className="percent" id="angular-pourcent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-symfony"></span>
                    </li>

                    <label>Github</label>
                    <span className="percent" id="angular-pourcent"></span>
                    <li>
                        <span className="progressbar progressblue" id="progress-git"></span>
                    </li>
                </ul>
            </div>
        </div>
    );
};



export default Skills