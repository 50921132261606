

import React from 'react';

import './award.css';

import { GiDiploma } from 'react-icons/gi';

const Award = () => {
    return (
        <div className="award_container d-flex align-items-center flex-wrap justify-content-between">

            <div className="award_item">
                <AwardItem
                    year='2022'
                    title='Bac +2 Développeur Web et Web mobile'
                    text="Webforce3"
                />
            </div>

            <div className="award_item">
                <AwardItem
                    year='2018'
                    title='CQP Employé de Commerce'
                    text="CCI"
                />
            </div>

            <div className="award_item">
                <AwardItem
                    year='2017'
                    title='CAP Boulanger'
                    text="Boulangerie RAMEAU"
                />
            </div>

            <div className="award_item">
                <AwardItem
                    year='2015'
                    title='Brevet des collèges'
                    text="Collège Jean-Bertin"
                />
            </div>
        </div>
    );
};

const AwardItem = ({ icon, year, title, text }) => {


    return <div className="single_award">
        <div className="award_year"><GiDiploma  className='diploma'/> {title} </div>
        <h6 className="award_title">
        {year} - {" "}
            <span>{text}</span>
        </h6>
    </div>

}


export default Award