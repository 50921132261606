import React from 'react'


const ServicesBox = ({imgName, box_name, p_name}) => {
  return (
    <div className="services_box mx-auto">
        <img className='w-50' src={imgName} alt="image-icon" />
        <h2>{box_name}</h2>
        <p>{p_name}</p>
    </div>


  )
}

export  { ServicesBox }