import React, { useRef, useEffect, useState } from 'react';
import './hero-section.css';
import './hero-section-mobile.css';
import './hero-section-tablet.css';


// import { Container, Row, Col } from "reactstrap";
// import { init } from "ityped";
import { useTypewriter, Cursor } from 'react-simple-typewriter'

import aboutImg from '../../assets/images/avatar-toon.png';
import CV from '../../assets/images/cv.pdf';

import {  gsap } from 'gsap';


// Award
import { FaAward } from 'react-icons/fa';

//Formation
import { GiTrophy } from 'react-icons/gi';
// Dossier 
import { TiFolderOpen } from 'react-icons/ti';
import CountUp from '../../CountUp/CountUp';


const HeroSection = () => {

  const { text } = useTypewriter({
    words: ['Lucas Besnard', 'Développeur Web'],
    typeSpeed: (50),
    deleteSpeed: (20),
    delaySpeed: (800),
    loop: (0),
  })

  // const [toggle, setToggle] = useState(false);

  // const changeState = () => {
  //   setToggle(!toggle)
  // }

  // const cardRef = useRef(null)
  var tl = gsap.timeline({ repeat: 0, repeatDelay: 1, delay: 0.5 });
  useEffect(() => {
    // ?
    // toogle &&

    tl.fromTo(".about_card",

      {
        y: 25,
        opacity: .5,
        stagger: 0.5,
        duration: 0.2,
        ease: "black.out(1.7)"
      },

      {
        y: 15,
        opacity: 1,
        stagger: 0.9,
        duration: 0.1,
        ease: "black.out(1.7)"
      }, 'Start');
  }, [])

  // {opacity: .5,
  //   scale: 0.8,
  // },
  // {
  //   opacity: 1,
  //   scale: 1,
  //   ease: "elastic.out(1, 0.3)",

  // },'Start');



  // useEffect(()=> {
  //   // ?
  //       // toogle &&
  //       gsap.to(cardRef.current, {opacity: .5,scale: 0,}, {opacity: 1, scale: 1,ease: "elastic.out(1, 0.3)"})
  //       // :
  //       // gsap.to(cardRef.current, {
  //       //   scale: 1,
  //       //   rotate: 0,
  //       //   duration: 1.2,
  //     }, [])
  //     // [toogle]  



  return (
    <section className="hero_section mt-5" id="home">
      <div className="container about_container">
        <div className="container">
          <div className="about_me">
            <div className="about_me-image">
              <img src={aboutImg} alt="About" />
            </div>
          </div>

          <div className="social_links text-center mt-4">
            <span><a href="https://www.linkedin.com/in/lucas-besnard-077469212/" target="_blank"><i class="ri-linkedin-box-fill"></i></a></span>
            <span><a href="https://github.com/LucasBesnard" target="_blank"><i class="ri-github-fill"></i></a></span>
          </div>
        </div>

        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className='about_icon' />
              <h5>Expériences</h5>
              <small>+ 7 mois</small>
            </article>

            <article className="about_card">
              <GiTrophy className='about_icon' />
              <h5>Diplôme</h5>
              <small>Développeur Web et Web mobile</small>
            </article>

            <article className="about_card card-projects">
              <TiFolderOpen className='about_icon' />
              <h5>Projets</h5>

              <small><CountUp end={10} /></small>
            </article>
          </div>

          <div className="hero_content">

            <h2 className="hero_title mt-5 mb-4">Je suis < span >{text}</span>< Cursor /></h2>

            <p>
              Avec mes différentes expériences professionnelles et privées, j’ai acquis une forte capacité d'adaptation et d’esprit d’équipe.
              Mes plus grandes valeurs sont le partage, l’amitié et la famille.
            </p>

            <p>Ma première expérience dans le domaine est dans les métiers du Numérique (Web Développeur, Web Designer, SEO, Marketing ...) avec Inco academy.</p>

            <p>Puis j'ai effectué ma formation de Développeur Web et Web Mobile dans un centre spécialiste du numérique Webforce 3. Lors de cette formation, j'ai acquis de nombreuses compétences et connaissances dans ce domaine. Lors de ma formation, j'ai réalisé un stage pour l'association Passerelle ou j'avais pour mission de réalisé un site web dans son entièreté.
            </p>


            <div className="mt-4 hero_btns d-flex align-items-center gap-4">
              <a href="#contact" className="btn hire_btn">Contact</a>
              <button className="btn">
                <a href={CV} download>Mon Cv</a>
              </button>
            </div>
          </div>
        </div>
      </div>


    </section>
  );
};

export default HeroSection