import React, { useRef, useEffect } from "react";
import { Container } from "reactstrap";

import './header.css';

const navLinks = [
    {
        display: "Accueil",
        url: "#home"
    },

    {
        display: "Profil",
        url: "#about"
    },


    {
        display: "Expériences",
        url: "#experience"
    },

    {
        display: "Centre Intérêts",
        url: "#services"
    },

    {
        display: "Portfolio",
        url: "#portfolio"
    },

    {
        display: "Contact",
        url: "#contact"
    },
];

const Header = () => {

    const headerRef = useRef(null);

    const menuRef = useRef(null)

    useEffect(() => {

        window.addEventListener("scroll", () => {
            if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
                headerRef.current.classList.add('header_shrink')
            } else {
                headerRef.current.classList.remove('header_shrink')
            }

        });

        return () => {
            window.removeEventListener("scroll", null);
        };

    }, []);

    const menuToggle = ()=> menuRef.current.classList.toggle('menu_active');

    // const handleClick = e => {
    //     e.preventDefault();
    //     const targetAttr = e.target.getAttribute('href')
    //     const location = document.querySelector(targetAttr).offSetTop

    //     window.scrollTo({
    //         left: 0,
    //         top: location - 70,
    //     })
    // }


    return (
        <header className="header" ref={headerRef}>
            <Container>
                <div className="navigation mt-1 d-flex align-items-center justify-content-between">
                    <div className="logo">
                        <h5>Lucas</h5>
                    </div>

                    <div className="nav_menu" ref={menuRef} onClick={menuToggle}>
                        <ul className="nav_list">
                            {navLinks.map((item, index) => (
                                <li className="nav_item" key={index}>
                                     {/* onClick={handleClick} */}
                                    <a href={item.url}>{item.display}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="nav_right d-flex align-items-center gap-4">
                        
                        <span className="mobile_menu">
                            <i class="ri-menu-5-line" onClick={menuToggle}></i>
                        </span>
                    </div>

                </div>

            </Container>
        </header>
    );
};

export default Header;
