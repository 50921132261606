import React from 'react'
import './services-mobile.css'
import './services-tablet.css'
import "../services/services.css"

import { ServicesBox } from "./ServicesBox";


import Numerique from '../../assets/images/num.png';
import Game from '../../assets/images/game.png';
import Culture from '../../assets/images/culture.png';
import Cine from '../../assets/images/cinema.png';



const Services = () => {
  return (
    <section className="services section reveal mb-5" id="services">
      <h2 className="section_title title-service">Centre Intérêts</h2>

      <div className="serviceContainer bg_grid">
        <div className="serviceIMage">

        <ServicesBox
            imgName={Numerique}
            box_name={"Le Numérique"}
            p_name={"Ma passion principale et mon avenir."}
          />

          <ServicesBox
            imgName={Culture }
            box_name={"Culture"}
            p_name={"Apprendre de nouvelles choses dans tous les domaines."}
          />

          <ServicesBox
            imgName={Cine}
            box_name={"Le Cinéma"}
            p_name={"Tout ce qui y touche, mais particulièrement les films."}
          />
          
          <ServicesBox
            imgName={Game}
            box_name={"Jeux Vidéos"}
            p_name={"Les jeux vidéo font en grande partie de ma vie."}
          />

        </div>

      </div>
    </section>
  )
}

export default Services