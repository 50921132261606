import React, { Fragment } from 'react';
import Header from '../header/Header';

import HeroSection from "../hero-section/HeroSection";
import About from "../about/About";

import Services from '../services/Services';

import { Experience } from '../experience/Experience';

import Portfolio from '../portfolio/Portfolio';

import Testimonials from '../testimonial/Testimonials';

import Contact from '../contact/Contact';

import Footer from '../footer/Footer';
import { TimelineLite,gsap } from 'gsap';

import ScrollReveal from 'scrollreveal';
import { useEffect } from 'react';


const Layout = () => {

useEffect(()=> {
  const sections = document.querySelectorAll('.reveal');
  ScrollReveal().reveal(sections, {
    delay: 275,
    duration: 500,
    reset: true,
    easeing: "ease-in",
  })
})


  return (<Fragment>
  <Header />
  <HeroSection />
  <About />
  <Experience />
  <Services />
  <Portfolio />
  <Contact />
  <Footer />
    </Fragment>
  );
};

export default Layout;