

import React, { useState } from 'react';
import './about.css';
import './about-mobile.css';
import './about-tablet.css';

import { Container, Row, Col } from "reactstrap";

import aboutImg from '../../assets/images/avatar-toon.png';

import Education from './Education';

import Skills from "./Skills"

import Award from './Award';




const About = () => {


    const [aboutFilter, setAboutFilter] = useState('ABOUT')

    return (
        <section className='mt-5 reveal' id="about">


            <Container className='mt-5'>
                <Row>
                    <Col lg='12' className='mb-5'>
                        <h2 className="text-center section_title">Profil</h2>{" "}
                    </Col>

                    <Col lg='4' md='3'>
                        <div className=" about_btns d-flex flex-column align-items-center text-center">

                            {/* ABOUTFILTET POUR LE HOVER SUR CAHQUE BUTTON  */}
                            <button className={`about_btn ${aboutFilter === 'ABOUT' ? 'about_btn-active' : ''}`} onClick={() => setAboutFilter('ABOUT')}>Profil</button>
                            {/* <button className={`about_btn ${aboutFilter === 'EDUCATION' ? 'about_btn-active' : ''}`} onClick={() => setAboutFilter('EDUCATION')}>Education</button> */}
                            <button className={`about_btn ${aboutFilter === 'SKILLS' ? 'about_btn-active' : ''}`} onClick={() => setAboutFilter('SKILLS')}>Compétences</button>
                            <button className={`about_btn ${aboutFilter === 'AWARD' ? 'about_btn-active' : ''}`} onClick={() => setAboutFilter('AWARD')}>Diplômes /   Formations</button>
                        </div>
                    </Col>

                    <Col lg='8' md='9'>


                        {
                            aboutFilter === 'ABOUT' && <div className="about_content_wrapper d-flex gap-5">
                                <div className="about_img w-25">
                                    <img src={aboutImg} alt="About" className="w-100" />
                                    <div className="social_link text-center">
                                        <h6 className="mt-4 mb-3">Connexion avec moi:</h6>
                                        <span><a href="https://www.linkedin.com/in/lucas-besnard-077469212/"><i class="ri-linkedin-box-fill"></i></a></span>
                                        <span><a href="https://github.com/LucasBesnard"><i class="ri-github-fill"></i></a></span>
                                    </div>
                                </div>



                                <div className="about_content ab-cont w-75">

                                    <h2>Mon Parcours :</h2>
                                    <p>J’ai commencé par un CAP boulanger que j’ai obtenu à l’âge de mes 18 ans. Ensuite, j’ai eu la possibilité d’essayer le commerce en faisant un CQP commerce à la CCI de l’ Yonne.</p>

                                    <p>Mes objectifs avec ses métiers étaient différents comme avoir le Permis B, une voiture par exemple et acquérir de l'expérience professionnelle, mais le principal était de gagner de l’argent tout en gardant ma passion en tête qui était de travailler dans le Numérique.
                                        Une fois mes objectifs atteints, j’ai décidé de tout faire pour travailler de ma passion.
                                    </p>

                                    <p>J’ai donc commencé par faire plusieurs formations, en orientation de projet comme le DAQ 2.0 qui est un dispositif en amont à la qualification au Greta d’Auxerre.
                                    Cette formation m'a permis de me faire ma première expérience dans le domaine du numérique. Puis j'ai directement poursuivi avec ma formation chez Webforce3.
                                    </p>

                                </div>

                            </div>
                        }


                        {
                            aboutFilter === 'EDUCATION' && <Education />
                        }

                        {
                            aboutFilter === 'SKILLS' && <Skills />
                        }

                        {
                            aboutFilter === 'AWARD' && <Award />
                        }

                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About